.project-card {
  --padding: 1rem;
  border-radius: 9px;
  max-width: 450px;
  margin: auto;
  padding: var(--padding);
  display: grid;
  box-shadow: 2px 2px 20px #6e6e6e80;

  border-bottom: 5px solid var(--clr-highlight);
  aspect-ratio: 1 / 0.7;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
  position: relative;
  background-color: white;
  z-index: 1;
  transition: transform 350ms ease, background-color 350ms ease,
    color 350ms ease, z-index 0ms 75ms linear;

  animation: updown 3s ease infinite;
}

.project-card:hover,
.project-card:focus {
  background-color: var(--clr-primary);
  transform: scale(1.35);
  border: 0;
  z-index: 10;
  transition: z-index 0ms 0ms ease, transform 350ms ease,
    background-color 350ms ease, color 350ms ease, box-shadow 350ms ease;
  box-shadow: 0px 0px 0px #fff;
  animation: none;
}

.project-card__title {
  width: min-content;
  margin: auto auto 0px 0px;
}

.project-card::before {
  content: "";
  position: absolute;
  inset: 0;
}

.project-card::before {
  pointer-events: none;
  opacity: 0;

  background: black;
  filter: blur(2em);
  z-index: -5;
  transition: transform 350ms ease, opacity 350ms ease;
}

.project-card:hover::before,
.project-card:focus::before {
  opacity: 0.3;
  transform: translate(-50%, 50%);
}

.project-card:hover,
.project-card:focus {
  background-color: var(--clr-primary);
  transform: scale(1.35);
  border: 0;
}

.project-card:hover .project-card__title,
.project-card:focus .project-card__title {
  color: var(--clr-neutral-100);
}

.project-card .project-card__buttons-container {
  display: block;
  font-size: 2rem;
  color: var(--clr-primary);
  inset: auto var(--padding) var(--padding) auto;
  opacity: 0;
  transform: translateX(-50%);
  transition: transform 500ms ease-in, opacity 500ms linear;
}

.project-card:hover .project-card__buttons-container,
.project-card:focus .project-card__buttons-container {
  opacity: 1;
  transform: translateX(0);
}

.project-card__content-container {
  display: flex;
  margin: auto 0px 0px;
  align-items: center;
}

.project-card__demo-button {
  color: #de4721;
  font-size: 5rem;
  margin: auto 10px auto 30px;
}

.project-card__thumbnail {
  max-width: 100%;
  opacity: 1;
  transition: all 0.2s;
  transform: scale(1);
  border-radius: 9px;
}

.project-card:hover .project-card__thumbnail,
.project-card:focus .project-card__thumbnail {
  opacity: 0;
  transform: scale(0);
}

.project-card__demo-button > * {
  filter: drop-shadow(1px 1px 4px #de4721);
  transition: all 0.2s;
  transform: scale(1);
  transition: transform 0.2s;
}

.project-card__demo-button > *:hover {
  filter: drop-shadow(1px 1px 10px #de4721);
  transform: scale(1.1);
}

.project-card__project-info {
  text-decoration: none;
  opacity: 0;
  transition: all 0.2s;
  color: #fff;
  position: absolute;
  margin: 1rem;
  font-family: "SF Display";
  font-weight: 400;
  transform: scale(1);
}

.project-card__project-info:hover {
  filter: drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.404));
  transform: scale(1.1);
}

.project-card__project-info:hover svg {
  filter: drop-shadow(1px 1px 4px rgba(255, 255, 255, 0.404));
}

.project-card:hover .project-card__project-info,
.project-card:focus .project-card__project-info {
  opacity: 1;
}

@keyframes updown {
  0%,
  50%,
  100% {
    transform: translatey(0);
  }
  20% {
    transform: translatey(5px);
  }
  25% {
    transform: translatey(-20px);
  }
  30% {
    transform: translatey(20px);
  }
  35% {
    transform: translatey(-5px);
  }
}

@media (max-width: 767px) {
  .project-card {
    animation: none;
    max-width: 80%;
  }

  .project-card__thumbnail {
    max-width: 90%;
  }

  .project-card .project-card__buttons-container {
    opacity: 1;
    transform: translateX(0);
  }

  .project-card__demo-button {
    margin: 0;
    right: 0;
  }
}
