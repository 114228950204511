.heroSection__container {
  padding-left: 10vw;
  padding-top: 35vh;
  width: fit-content;
}

.heroSection__contentTitle {
  font-size: 6rem;
  font-weight: 300;
}

.heroSection__contentSubtitle {
  font-size: 4.3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.heroSection__contentParagraph {
  font-size: 2rem;
  font-weight: 300;
}

@media (max-width: 767px) {
  .heroSection__container {
    padding-left: 0vw;
  }

  .heroSection__contentTitle {
    font-size: 3rem;
  }

  .heroSection__contentSubtitle {
    font-size: 2.2rem;
  }

  .heroSection__contentParagraph {
    font-size: 1.2rem;
  }
}
