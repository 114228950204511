@font-face {
  font-family: "SF Display";
  font-style: normal;
  font-weight: normal;
  src: url(./assets/fonts/sf-display/SFPRODISPLAYREGULAR.woff2) format("woff2");
}

* {
  margin: 0px;
  scroll-behavior: smooth;
  cursor: url(assets/Images/cursers/auto.png), auto;
}

.onHover--pointer,
.onHover--pointer * {
  margin: 0px;
  cursor: url(assets/Images/cursers/pointer.png), pointer;
}

body {
  font-family: -apple-system, Helvetica, BlinkMacSystemFont, Helvetica,
    "SF Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #242424;

  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}
