.experienceTitle__containerLeft {
  display: flex;
  align-items: center;
  margin-bottom: 120px;
  margin-top: 200px;
}

.experienceTitle__timelineMark {
  width: 33px;
  height: 33px;
  background-color: #eeeeee;
  border-radius: 90px;
  margin-left: -16.5px;
  z-index: 30;
  position: absolute;
}

.experienceTitle__image {
  max-height: 170px;
  margin-left: 40px;
  max-width: 65%;
}

.experienceTitle__containerRight {
  color: #242424;
  font-family: Helvetica;
  position: relative;
  margin-bottom: 150px;
  margin-top: 200px;
}

.experienceTitle__titleContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.experienceTitle__title {
  font-size: 2.6rem;
  font-weight: 500;
  margin-bottom: 15px;
  text-decoration: underline 3px;
  text-decoration-color: #de4721;
  text-underline-offset: 3px;
}

.experienceTitle__subTitble {
  font-size: 1.25rem;
}

.experienceTitle--wideSubtitle {
  margin: 0px 30px;
}

@media (max-width: 1024px) {
  .experienceTitle__containerLeft {
    margin: 140px auto 0 0;
  }
  .experienceTitle__containerRight {
    margin: 40px auto;
  }

  .experienceTitle__image {
    max-width: 70%;
  }

  .experienceTitle__timelineMark {
    width: 20px;
    height: 20px;
    margin-left: -10px;
  }

  .experienceTitle__titleContainer {
    position: relative;
    text-align: center;
  }

  .experienceTitle__title {
    font-size: 1.7rem;
    margin: auto;
    text-align: center;
  }

  .experienceTitle--wideSubtitle {
    margin: auto 10px;
  }
}
