.experience-task__Wrapper {
  height: 500px;
  margin-top: 75px;
  margin-bottom: 75px;
}

.experienceTask__imageContainer {
  max-height: 550px;
  margin: 30px auto;
}

.experienceTask__image {
  object-fit: contain;
  display: block;
  max-width: 80%;
  height: 90%;
  margin: auto;
  transform: scale(1);
  transition: transform 350ms ease-in-out;
}

.experienceTask__image:hover,
.experienceTask__image:focus {
  transform: scale(1.2);
  border: 0;
}

.experienceTask__descriptionContainer {
  --text-padding: 70px;

  width: 100%;
  max-width: 950px;
  color: #242424;
  margin: 30px auto;
}

.experienceTask__title {
  font-size: 2.2rem;
  font-weight: 500;
  padding-right: var(--text-padding);
  padding-left: 0px;
}

.experienceTask__description {
  max-width: 100%;
  font-family: "Helvetica";
  text-align: justify;
  font-size: 1.3rem;
  line-height: 1.7;
  margin-top: 20px;
  padding-right: var(--text-padding);
  padding-left: 0px;
}

.experienceTask--textRight {
  text-align-last: right;
  padding-left: var(--text-padding);
  padding-right: 0px;
}

.experienceTask__video {
  object-fit: contain;
  max-width: 80%;
  margin: auto;
  display: block;
}

@media (max-width: 1024px) {
  .experienceTask__descriptionContainer {
    margin: 0 auto 50px;
  }
  .experienceTask__title {
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
  }

  .experienceTask__description {
    margin-top: 0;
    max-width: 100%;
    text-align: justify;
    text-align-last: center;
    padding: 15px 25px 75px;
    font-size: 1rem;
  }
  .experienceTask--textRight {
    text-align-last: center;
    padding: 10px;
  }

  .experience-task__Wrapper {
    height: fit-content;
    margin: auto;
  }
}
