.skills__container {
  margin: auto;
  max-width: 1300px;
}

.skills__card-grid {
  columns: 2;
  margin-top: 140px;
}
.skills__card-grid > * {
  display: inline-block;
  width: calc(100% - 100px);
}

.skill__property {
  font-size: 2rem;
  color: #a0a0a0;
  line-height: 2.2;
  font-family: "SF Display";
  font-weight: normal;
}

@media (max-width: 1024px) {
  .skills__card-grid {
    columns: 1;
  }
}

@media (max-width: 767px) {
  .skill__property {
    font-size: 1.5rem;
  }
}
