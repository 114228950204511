.progress-bar {
  display: flex;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 40px;
  font-family: "SF Display";
}

.progress-bar__step-container {
  display: block;
  text-align: center;
}

.progress-bar__step-number {
  color: #242424;
  background-color: #edf0f3;
  border-radius: 10px;
  padding: 8px 10px;
  text-align: center;
  max-height: 20px;
  transition: all 200ms ease;
}

.progress-bar__step-number--active {
  color: #fff;
  background-color: #242424;
}

.progress-bar__step-title {
  margin-top: 15px;
}

.progress-bar__line {
  flex: 1;
  margin: 10px -50px;
  height: 4px;
  z-index: -1;
}

.progress-bar__line--active line {
  stroke: #242424 !important;
}

.progress-bar__line line {
  stroke: #edf0f3;
  stroke-width: 4;
  transition: all 200ms ease;
}

@media (max-width: 1024px) {
  .progress-bar {
    max-width: 80%;
  }
}
