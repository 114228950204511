.interactive-hamburger {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 120px;
  min-height: 120px;
  display: flex;
}

.interactive-hamburger__wrapper {
  margin: 0px 0px auto auto;
  position: relative;

  min-width: 100px;
  min-height: 100px;
  border-radius: 50px;
}

.interactive-hamburger__icon-container {
  margin: auto;

  box-shadow: 0 0 20px 20px rgb(0 0 0 / 3%);
  border-radius: 50px;
  background-color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.2s ease-out;
}

.interactive-hamburger__icon {
  color: #898989;
  font-size: 20px;
}
