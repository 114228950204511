.form-card {
  background-color: white;
  border: 1px solid #333;
  border-radius: 0.5rem;
  padding: 25px 40px;
  width: min(100%, 650px);

  position: absolute;
  top: 30px;
  left: 50%;
  border: none;
  box-shadow: 2px 2px 20px #6e6e6e;
}

.step-title {
  margin: 15px auto 25px;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
  font-family: "SF Display";
  font-weight: 400;
}

.form-card.active {
  animation: slide 250ms 125ms ease-in-out both;
}

.form-card.hide {
  animation: downsize 250ms ease-in-out both;
}

.hidden {
  display: none;
}

.multi-step-form__buttons-container {
  display: flex;
  justify-content: center;
  margin: 20px auto 0px;
}

.multi-step-form__buttons-container > * {
  margin: auto 10px;
}

.multi-step-form__button {
  margin: 0px 20px;
  width: 150px;
  height: 50px;
}

@keyframes slide {
  0% {
    transform: translate(200%, 0%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@keyframes downsize {
  0% {
    transform: scale(1) translate(-50%, 0%);
    opacity: 1;
  }
  50% {
    transform: scale(0.75) translate(-50%, 0%);
    opacity: 0;
  }
  100% {
    transform: scale(0) translate(-50%, 0%);
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .form-card {
    max-width: 70%;
    padding: 0px 20px 10px;
  }
  .step-title {
    font-size: 1.75rem;
  }
}
