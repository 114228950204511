.header {
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
}

.header * {
  font-size: 1.3rem;
}
