.contact {
  margin-top: 100px;
}

.multi-step-form {
  overflow: hidden;
  position: relative;
  height: 850px;
}

.contact__source-container {
  display: block;
  margin: 250px auto -150px;
  padding-bottom: 15px;
  text-decoration: none;
}

.contact__confirmation-icon {
  margin: 50px auto 40px;
  font-size: 40px;
  color: #de4721;
  display: block;
}
@media (max-width: 1024px) {
  .contact {
    max-width: 100%;
  }
  .contact__source-container {
    margin: 250px auto -100px;
  }
}
