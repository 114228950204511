.card {
  padding: 30px;
  border: none;
  border-radius: 0.5rem;

  box-shadow: 2px 2px 20px #6e6e6e;
  margin: 20px;
}

.card__title {
  font-family: "SF Display";
  font-weight: normal;
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 40px;
}

.card__title--underline {
  text-decoration: underline;
  text-decoration-color: #de4721;
  text-underline-offset: 2px;
}

@media (max-width: 767px) {
  .card__title {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}
