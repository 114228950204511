.greetingsPopup {
  position: fixed;
  inset: 0;
  background-color: #242424;
  height: 100vh;
  width: 100vw;
  margin-bottom: -100vh;
  margin-top: 0vh;
  opacity: 1;
  transition: all 1s 1s;
  z-index: 100;
}

.greetingsPopup--disapear {
  margin-top: -100vh;
  opacity: 0.9;
}

.greetingsPopup__title {
  font-family: Helvetica;
  font-size: 7rem;
  color: #242424;
  -webkit-text-stroke: 2px #bab8bd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
