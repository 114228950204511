.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  gap: 0.25em;
  font-family: "SF Display";
  font-weight: 500;
}

.form-field__input,
.form-field__text-editor {
  margin: 5px 0px;
  border: 3px solid #dad7d8;
  border-radius: 0.25em;
  font-size: 1.75rem;
}

.form-field__input {
  padding: 30px;
}

.form-field__input::placeholder,
.form-field__text-editor .public-DraftEditorPlaceholder-root {
  color: #a0a0a0;
  text-transform: capitalize;
}

.form-field__text-editor-content {
  padding: 30px;
  min-height: 250px;
  max-height: 250px;
  overflow-x: auto;
  font-size: 1.5rem;
}

.form-field__input--error {
  border: #ff0000 3px solid;
}

.form-field__error {
  color: #ff0000;
  font-size: 1.4rem;
  line-height: 2rem;
}

@media (max-width: 1024px) {
  .form-field__input,
  .form-field__text-editor-content {
    padding: 10px;
  }

  .form-field__input,
  .form-field__text-editor {
    margin: 4px 0px;
    border: 2px solid #dad7d8;
    font-size: 1.5rem;
  }

  .form-field__input--error {
    border: #ff0000 2px solid;
  }
}
