.timeline-page {
  display: flex;
}

.timeline-page__content {
  margin: 200px 0px;
  flex: 1;
  min-height: calc(100vh - 400px);
  max-width: 100%;

  overflow-x: clip;
}

@media only screen and (max-width: 1024px) {
  .timeline-page__content {
    min-height: calc(100vh - 200px);
    margin: 100px 0px;
  }
}
