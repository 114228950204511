.circle-button__title {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
  margin-right: 10px;
}

.circle-button .circle-button__icon {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.circle-button:hover::before {
  width: 100%;
  background: rgba(#ffab9d, 1);
  opacity: 1;
}

.circle-button:hover .circle-button__icon {
  transform: translateX(0);
}

.circle-button:hover:active {
  transform: scale(0.96);
}
