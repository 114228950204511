.recentProject {
  margin-top: 150px;
  margin-left: 100px;
  max-width: 1400px;
  display: flex;
  transform: scale(0.8, 0.8);
}

.project-cards-grid {
  --clr-primary: #242424;
  --clr-neutral-400: hsl(210, 1%, 64%);
  --clr-neutral-100: hsl(210, 20%, 94%);

  --clr-highlight: #de4721;

  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 200px auto;
  z-index: 100;
  transform: scale(0.9, 0.9);
}

.recentProject__image {
  width: 40%;
  margin: auto 5%;
  border-radius: 20px;
}

.recentProject__title {
  font-size: 2.6rem;
}

.recentProject__description {
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 15px 0px;
  text-align: justify;
  color: #4b4b4b;
}

.recentProject__btnsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.recentProject__button {
  border-radius: 20px;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.recentProject--demoBtn {
  background-color: #48548a;
  color: white;
}

.recentProject--demoBtn:hover {
  background-color: #414a73;
}

.recentProject__buttonIcon {
  height: 25px;
  object-fit: contain;
  margin-left: 10px;
}

.recentProject--projectBtn {
  background-color: transparent;
  color: #414a73;
  border: 4px #414a73 solid;
}

.recentProject--projectBtn:hover {
  background-color: #e5e3e6;
}

@media (max-width: 767px) {
  .project-cards-grid {
    margin: 10px;
    max-width: 100%;
  }
}
