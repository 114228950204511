.AboutMe__paragraph {
  width: 73%;
  max-width: 1400px;
  margin: 10% auto auto;
  font-size: 2rem;
  color: #4b4b4b;
  font-family: "SF Display";
  line-height: 1.5;
}

@media only screen and (max-width: 1024px) {
  .AboutMe__paragraph {
    font-size: 1.5rem;
  }
}
