.dropdown {
  user-select: none;
  position: relative;
  z-index: 10;
}

.dropdown__btn {
  padding: 15px 20px;
  color: #232f3e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown__content {
  position: absolute;
  top: 110%;
  left: 0;
  padding: 5px 10px;
  background: #fff;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: normal;
  color: #232f3e;
}

.dropdown__arrow {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.dropdown__arrow--flipped {
  transform: rotate(180deg);
}
